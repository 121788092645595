import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { CaixaPostalService } from "src/app/services/caixa-postal.service";
import * as dayjs from "dayjs";
import "dayjs/locale/pt-br";
import Swal from "sweetalert2";

@Component({
  selector: 'app-caixa-postal',
  templateUrl: './caixa-postal.component.html',
  styleUrls: ['./caixa-postal.component.scss']
})
export class CaixaPostalComponent implements OnInit {

  loading: boolean = false;
  comunicados: any = [];
  prazoResposta: any;
  dadosUsuario: any;
  objUsuario: any;
  quantidadePaginas: number = 30;
  quantidadeItens: number = 50;
  searchParams: any = {};
  pagina: number = 1;
  tamanhoPagina: number = 10;
  totalElementos: number;
  dataInicio: any;
  dataFim: any;
  filtroData = {
    dataInicio: null,
    dataFim: null
  }
  displayDate: any;
  seletorFiltro: string = '';
  dadoFiltro: any = '';
  parametros: Object = {};
  radioComunicacao: boolean = false;
  radioValidade: boolean = false;
  radioSituacao: boolean = false;
  situacaoFiltro: any = '';
  validadeFiltro: any = '';
  protocolo: any;
  filtroAtivo: boolean = false;
  estadoDataInicial: dayjs.Dayjs = dayjs();
  exibirTipoComunicado: boolean = false;
  tipoComunicadoSelecionado: any = null;
  exibirEnviarComunicado: boolean = false;
  quantidadeElementosPagina: number;
  listaTiposComunicacao: any;

  constructor(private caixaPostalService: CaixaPostalService,
    private datePipe: DatePipe,
    private router: Router) {
    dayjs.locale('pt-br');
  }

  ngOnInit(): void {
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.getComunicados();
    this.getTiposComunicacao();
  }

  getComunicados() {
    this.loading = true;
    this.searchParams.pagina = this.pagina;
    this.searchParams.tamanhoPagina = this.tamanhoPagina;

    this.caixaPostalService.getComunicados(this.objUsuario.codigo, this.searchParams).subscribe(
      res => {
        this.comunicados = res.comunicados.map(comunicado => {
          return {
            ...comunicado,
            naoLido: comunicado.situacaoCaixaPostal.some(situacao => situacao.tipoSituacaoCaixaPostal.codigo === 1),
            teveCienciaExpressa: comunicado.ciencias.some(ciencia => ciencia.tipoCiencia.codTipoCiencia === 2),
            ultimaResposta: comunicado.ultimaResposta?.situacaoCaixaPostal.some(
              situacao => situacao.tipoSituacaoCaixaPostal.codigo === 1),
            corLetra: this.definirCorLetrasComunicado(comunicado)
          };    
        });

        this.quantidadeElementosPagina = this.comunicados.length;

        if (this.comunicados.length == 0) {
          this.pagina = 1;
          this.quantidadePaginas = 1;
        } else {
          this.quantidadePaginas = res['totalElementos'] / res['tamanhoPagina'];
          this.quantidadePaginas = Math.ceil(this.quantidadePaginas);
          this.totalElementos = res['totalElementos'];
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao abrir a Caixa Postal'
        });
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  definirCorLetrasComunicado(comunicado) {
    const passouPrazoResposta = dayjs().isAfter(dayjs(comunicado.datPrazoResposta));
    const passou10diasEnvio = dayjs().isAfter(dayjs(comunicado['datCaixaPostal']).add(10, 'day'));
    const passou30diasDoEnvio = dayjs().isAfter(dayjs(comunicado['datCaixaPostal']).add(30, 'day'));
    const passou40diasEnvio = dayjs().isAfter(dayjs(comunicado['datCaixaPostal']).add(40, 'day'));
    const passouPrazoCiencia = dayjs().isAfter(dayjs(comunicado['datPrazoCiencia']));

    if(comunicado.datResposta !== undefined) {
      return ''
    } else if((comunicado['datPrazoResposta'] && passouPrazoResposta) || (!comunicado.datPrazoResposta && passou40diasEnvio)
    ) {
      return 'vermelho';
    } else if(
      (comunicado['ciencias'].length !== 0 && comunicado.ciencias[0].tipoCiencia.codTipoCiencia == 1 && comunicado.datPrazoResposta !== undefined && !passouPrazoResposta) || 
      (comunicado.datPrazoResposta == undefined && passou30diasDoEnvio)
    ) {
      return 'amarelo';
    } else if((comunicado.datPrazoCiencia !== undefined && !passouPrazoCiencia) || (comunicado.datPrazoCiencia == undefined && comunicado.situacaoCaixaPostal.length == 0 && !passou10diasEnvio)) {
      return 'verde';
    }
    return ''
  }

  getTiposComunicacao() {
    this.caixaPostalService.getTipoComunicacao().subscribe((res) => {
      this.listaTiposComunicacao = res;
    });
  }

  getFiltroDataInicio(event) {
    this.filtroData.dataInicio = dayjs(this.dataInicio).format('YYYY-MM-DD');
    if (this.dataInicio && this.dataFim && this.dataInicio > this.dataFim) {
      setTimeout(() => {
        this.filtroData.dataFim = null;
        this.dataFim = null;
      }, 150);
    }
  }

  getFiltroDataFim(event) {
    this.filtroData.dataFim = dayjs(this.dataFim).format('YYYY-MM-DD');
    if (this.dataFim < this.dataInicio) {
      this.dataFim = null;
      this.filtroData.dataFim = null;
    }
  }

  getComunicadoEletronicoFiltro(event) {
    this.validadeFiltro = '';
    this.seletorFiltro = event.target.name;
    if (event.target.value == this.situacaoFiltro) {
      this.situacaoFiltro = '';
      this.seletorFiltro = '';
      this.radioSituacao = null;
    } else {
      if (event.target.value === "respondido" ||
          event.target.value === "naoRespondido" ||
          event.target.value === "lido") {
        this.situacaoFiltro = event.target.value;
      } else {
        this.dadoFiltro = event.target.value;
      }
    }
  }

  getFiltroTipoComunicacao(codigo) {
    if (codigo == this.dadoFiltro) {
      this.seletorFiltro = '';
      this.dadoFiltro = '';
      this.radioComunicacao = null;
    } else {
      this.seletorFiltro = 'tipoComunicacao';
      this.dadoFiltro = codigo;
    }
  }

  getFiltroValidade(event) {
    this.situacaoFiltro = '';
    this.seletorFiltro = event.target.name;
    if (event.target.value == this.validadeFiltro) {
      this.validadeFiltro = '';
      this.seletorFiltro = '';
      this.radioValidade = null
    } else {
      if (event.target.value === "prazoRespostaVencido" ||
        event.target.value === "prazoCienciaVencer" ||
        event.target.value === "comCiencia" ||
        event.target.value === "prazoRespostaVencer") {
        this.validadeFiltro = event.target.value;   
      } else {
        this.dadoFiltro = event.target.value;
      }
    }
  }

  filtrarComunicadoEletronico() {
    this.pagina = 1;
    this.searchParams['filtro'] = this.seletorFiltro;
    this.searchParams['dados'] = this.dadoFiltro;
    this.searchParams['situacao'] = this.situacaoFiltro;
    this.searchParams['validade'] = this.validadeFiltro;
    this.filtroAtivo = true;
    if (this.filtroData.dataInicio !== null && this.filtroData.dataFim !== null) {
      this.searchParams['inicio'] = this.filtroData.dataInicio;
      this.searchParams['fim'] = this.filtroData.dataFim;
    }
    this.getComunicados();
  }

  limparFiltro() {
    delete this.searchParams['filtro'];
    delete this.searchParams['dados'];
    delete this.searchParams['situacao'];
    delete this.searchParams['validade'];
    delete this.searchParams['fim'];
    delete this.searchParams['inicio'];
    this.dadoFiltro = '';
    this.filtroData.dataInicio = null;
    this.filtroData.dataFim = null;
    this.protocolo = '';
    this.radioComunicacao = null;
    this.radioValidade = null;
    this.radioSituacao = null;
    this.pagina = 1;
    this.situacaoFiltro = '';
    this.validadeFiltro = '';
    this.filtroAtivo = false;
    this.alterarCalendario();
    this.getComunicados();
  }

  alterarCalendario() {
    this.dataInicio = this.estadoDataInicial;
    this.dataFim = this.estadoDataInicial;
    this.estadoDataInicial = dayjs();
  }

  atualizar() {
    this.loading = true;
    this.pagina = 1;
    this.getComunicados();
  }

  exibirModalTipoComunicado() {
    this.exibirTipoComunicado = !this.exibirTipoComunicado;
  }

  exibirModalEnviar(tipoComunicado) {
    this.tipoComunicadoSelecionado = tipoComunicado;
    this.exibirTipoComunicado = false;
    this.exibirEnviarComunicado = true;
  }

  fecharEnviarComunicado() {
    this.exibirEnviarComunicado = false;
    this.getComunicados();
  }

  redirect(comunicado: number) {
    this.router.navigate(['/caixa-postal/comunicado/' + comunicado]);
  }

  formatDate(date: string): string | null {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  getDataLeitura(situacaoCaixaPostal: any[]): string | null {
    if (!situacaoCaixaPostal || situacaoCaixaPostal.length === 0) {
      return null; 
    }
    
    const situacaoLida = situacaoCaixaPostal.find(
      (situacao) => situacao.tipoSituacaoCaixaPostal?.codigo === 1
    );
  
    return situacaoLida 
      ? this.datePipe.transform(situacaoLida.datSituacaoCaixaPostal, 'dd/MM/yyyy')
      : null;
  }
  

  alterarTamanhoPagina(event) {
    this.tamanhoPagina = event.target.value;
    this.pagina = 1;
    this.getComunicados();
  }

  primeiraPagina() {
    this.loading = true;
    this.pagina = 1;
    this.getComunicados();
    this.scroll();
  }

  paginaAnterior() {
    this.loading = true;
    this.pagina -= 1
    this.getComunicados();
    this.scroll();
  }

  proximaPagina() {
    this.loading = true;
    this.pagina += 1;
    this.getComunicados();
    this.scroll();
  }

  ultimaPagina() {
    this.loading = true;
    this.pagina = this.quantidadePaginas;
    this.getComunicados();
    this.scroll();
  }

  scroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  

}
